<script>
export default {
  data() {
    return {
      src:'',
    }
  },
  props:{
    url:{
      type:String,
      default:''
    },
    color:{
      type:Array,
      default:()=>{
       return [93,95,102]
      }
    }
  },
  watch:{
    url:{
      handler(val){
        if (val){
          this.changeImageColor()
        }
      },
      immediate: true
    }
  },
  methods: {
    changeImageColor() {
      let image = new Image();
      image.src = this.url;
      const colors=this.color;
      image.onload = ()=> {
        let newCanvas = document.createElement("canvas");
        let ctx = newCanvas.getContext("2d");
        newCanvas.width = image.width;
        newCanvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        let imageData = ctx.getImageData(0, 0, newCanvas.width, newCanvas.height);
        //imageData 则为图片内每个像素点的RGB信息
        for (let p = 0; p < imageData.data.length; p += 4) {
          if (imageData.data[p + 3] !== 0) {
            //判断当前像素点的A值不为0 (RGBA A为透明度) 则替换成目标颜色
            imageData.data[p] = colors[0];
            imageData.data[p + 1] = colors[1];
            imageData.data[p + 2] = colors[2];
          }
        }
        ctx.putImageData(imageData, 0, 0);

        const dataUri = newCanvas.toDataURL("image/png");
        // 清除画布
        ctx.clearRect(0, 0, image.width, image.height);
        this.src=dataUri;
      }
      return image;
    }
  }
}
</script>

<template>
  <img :src="src">
</template>

<style scoped lang="scss">
</style>