<script>
export default {
  data() {
    return {
      paddingRight: '0.8rem'
    }
  },
  props: {
    data: {
      type: Object,
      default: null
    },
  },
  methods: {
    getContent() {
      let val = ""
      if (!this.data) {
        return
      }
      val = this.data.content;
      let res = this.$emo.transform(val)
      let url = this.getUrl(res);
      if (url.length > 0) {
        for (let u of url) {
          res = res.replace(u, `<a href="${u}" target="_blank">${u}</a><span class="cpbutton" onclick="copyUrl('${u}')">复制</span>`)
        }
      }
      return res;
    },
    getUrl(str) {
      const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      const res = [];
      const strValue = str.match(reg);
      if (strValue && strValue.length > 0) {
        return strValue;
      }
      return res;
    },
    copyContent(data) {
      this.$message({
        type: "success",
        message: "已复制"
      })
      navigator.clipboard.writeText(data)
    },
    measurePaddingRight() {
      if (!this.$refs.item) {
        return;
      }
      const width = this.$refs.item.clientWidth;
      const screenWidth = document.body.clientWidth;
      if (width / screenWidth < 0.6) {
        this.paddingRight = '80px'
      }
      console.log(width, screenWidth, width / screenWidth, this.paddingRight);
    },
  },
  mounted() {
    this.measurePaddingRight()
  },
  created() {
    window.copyUrl = this.copyContent
  }
}
</script>

<template>
  <div style="width: 100%;">
    <div class="text self" ref="item" :style="{paddingRight:paddingRight}" v-html="getContent()"></div>
    <div class="self_time">
      <div style="font-size: 0.7rem;">22:22</div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.text {
  margin-left: 0.6rem;
  background: #ef9c9c;
  max-width: calc(100% - 6rem);
  word-break: break-all;
  padding: 0.8rem;
  box-sizing: border-box;
  font-size: 0.85rem;
  border-radius: 0.4rem;
}
.self {
  background: #d6fbd0;
  margin-right: 0.6rem;
  margin-left: 0;
  position: relative;
  padding-bottom: 20px;
}

</style>